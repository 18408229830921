import React, { useCallback, useEffect, useState } from "react";
import HeaderList from "../../components/HeaderList";
import EmergencyBranchUI from "./components/EmergencyBranchUI";
import { useDispatch, useSelector } from "react-redux";
import {
  createBranchBackupThunk,
  deleteBranchBackupThunk,
  getAllBranches,
  getBranchesBackupThunk,
  selectBranches,
  selectBranchesBackup,
} from "../../redux/slices/branches";
import { selectUser } from "../../redux/slices/user";
import { Form, Modal, message } from "antd";

const Main = () => {
  const branches = useSelector(selectBranches);
  const branchesBackup = useSelector(selectBranchesBackup);
  const [form] = Form.useForm();
  const [branchBackup,handleBranchBackup] = useState(undefined)
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [loading, handleLoading] = useState(true);
  const [sending, handleSending] = useState(false);
  const initialFetch = useCallback(async () => {
    const [response] = await Promise.all([
      dispatch(getBranchesBackupThunk(user.id)),
      dispatch(getAllBranches(user.id)),
    ]);
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    }
    handleLoading(false);
  }, [dispatch, user]);

  const onFinish = async (values) => {
    try {
      values.userId = user.id;
      handleSending(true);
      const response = await dispatch(createBranchBackupThunk(values));
      if (response.status === "success") {
        message.success("Sucursal de emergencia guardada exitosamente!");
      } else {
        message.error("¡Hubo un problema! Inténtalo de nuevo");
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleSending(false);
    }
  };
  useEffect(() => {
    initialFetch();
  }, [initialFetch]);
  const confirmDelete = (id) => {
    console.log(id);
    Modal.confirm({
      title: "¿Esta seguro de restablecer esta sucursal?",
      content: "",
      okText: "Restablecer",
      okType: "danger",
      cancelText: "Cancelar",
      async onOk() {
        await removeBranchBackup(id);
      },
      onCancel() {},
    });
  };
  const removeBranchBackup = async (id) => {
    const response = await dispatch(deleteBranchBackupThunk(user.id, id));
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    } else {
      message.success("Sucursal restablecida correctamente");
    }
  };
  useEffect(() => {
     if(branchBackup){
      form.setFieldsValue({NoBackup:false})
     }
  }, [branchBackup])
  
  return (
    <div>
      <HeaderList title={"Sucursal Emergencia"} back={"/sucursales"} />
      <EmergencyBranchUI
        branches={branches}
        branchesBackup={branchesBackup}
        loading={loading}
        form={form}
        onFinish={onFinish}
        confirmDelete={confirmDelete}
        sending={sending}
        handleBranchBackup={handleBranchBackup}
        branchBackup={branchBackup}
      />
    </div>
  );
};

export default Main;
