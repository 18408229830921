import { Button, Checkbox, Form, Input, Select, Spin, Table } from "antd";
import styles from "../styles/EmergencyBranchUI.module.css";
import cx from "classnames";
import { ReactComponent as Branch } from "../../../assets/icons/branch.svg";
import { ReactComponent as Trash } from "../../../assets/icons/trash.svg";
import moment from "moment";

const EmergencyBranchUI = ({
  branches,
  branchesBackup,
  loading,
  form,
  onFinish,
  confirmDelete,
  sending,
  handleBranchBackup,
  branchBackup,
}) => {
  const columns = [
    { title: "Domicilio Afectado", dataIndex: "branch_origin", align: "center" },
    { title: "Domicilio de Ayuda", dataIndex: "branch_backup", align: "center" },
    { title: "Razón del cambio",dataIndex:"backup_reason",align:"center"},
    { title: "Fecha de Cambio", dataIndex: "backup_time", align: "center", },
    {
      title: "Opciones",
      dataIndex: "id",
      align: "center",
      render: (id) => (
        <div className={styles.actions} onClick={() => confirmDelete(id)}>
          <Trash />
        </div>
      ),
    },
  ];
  const mapBranches = branches.map((item) => {
    return { value: item.id, label: item.zone };
  });
  console.log(branchesBackup);
  return (
    <div className={styles.view}>
      <p className={styles.title}>Cambios de Domicilio en Caso de Emergencia</p>
      <div className={styles.containerForm}>
        <Form layout="vertical" onFinish={onFinish} form={form} initialValues={{NoBackup:false}}>
          <Form.Item
            label={
              <span className={cx(styles.label, styles.labelHour)}>
                DOMICILIO AFECTADO
              </span>
            }
            name="branchAffected"
            rules={[{ required: true, message: "Ingresar Domicilio Afectado" }]}
          >
            <Select
              size="large"
              className={styles.input}
              options={mapBranches}
            />
          </Form.Item>
          <Form.Item
            label={
              <span className={cx(styles.label, styles.labelHour)}>
                EL DOMICILIO AFECTADO NO TENDRÁ AYUDA
              </span>
            }
            name="NoBackup"
            rules={[{ required: branchBackup ? false : true, message: "Ingresar chequeo" }]}
            valuePropName="checked"
          >
            <Checkbox size="large" className={styles.input}  disabled={branchBackup} />
          </Form.Item>
          <Form.Item
            label={
              <span className={cx(styles.label, styles.labelHour)}>
                DOMICILIO DE AYUDA
              </span>
            }
            name="branchBackup"
            rules={[{ required: branchBackup ? true : false, message: "Ingresar Domicilio De Ayuda" }]}
          >
            <Select
              size="large"
              className={styles.input}
              options={mapBranches}
              allowClear
              onChange={handleBranchBackup}
            />
          </Form.Item>
          <Form.Item
            label={
              <span className={cx(styles.label, styles.labelHour)}>
                RAZÓN DEL CAMBIO
              </span>
            }
            name="BackupReason"
            rules={[{ required: true, message: "Ingresar Razón del Cambio" }]}
          >
            <Input.TextArea rows={3} className={styles.input} />
          </Form.Item>
          <Button
            loading={sending}
            className={styles.submit}
            size="large"
            type="primary"
            htmlType="submit"
          >
            GUARDAR SUCURSAL
          </Button>
        </Form>
      </div>
      <span className={styles.tableLabel}>
        <Branch />
        <p>Domicilios sin servicio</p>
      </span>
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <Table columns={columns} dataSource={branchesBackup} />
      )}
    </div>
  );
};

export default EmergencyBranchUI;
